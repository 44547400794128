
import { Component, Vue } from "vue-property-decorator"
import { FetchResult } from "apollo-link"
import AppPage from "@/components/layout/AppPage.vue"
import {
  PerformanceAppraisalsQuery,
  MissionPerformanceAppraisal,
  ConsultantsSearchQuery,
  MissionLifecycleSelectQuery,
  PerformanceAppraisalsQueryQuery,
} from "@/gql"

@Component({
  components: {
    AppPage,
  },
})
export default class Appraisals extends Vue {
  readonly missionLifecycleSelectquery = MissionLifecycleSelectQuery
  readonly performanceAppraisalsQuery = PerformanceAppraisalsQuery
  readonly consultantsSearchQuery = ConsultantsSearchQuery
  paginate = { page: 1, per: 30 }

  showReviewDialog = false
  activePerformanceAppraisal: MissionPerformanceAppraisal | null = null

  pendingCount = 0
  totalCount = 0

  get performanceAppraisalsQueryVars() {
    return {
      filter: {
        state: "PENDING",
      },
      sort: {},
      page: 1,
      per: 6,
    }
  }

  readonly headers = [
    {
      text: "Appraisee",
      value: "appraisee.name",
      sortable: false,
    },
    {
      text: "Appraisee's Role",
      value: "appraiseeRole.name",
      sortable: false,
    },
    {
      text: "Appraiser",
      value: "appraiser.name",
      sortable: false,
    },
    {
      text: "Appraiser's Role",
      value: "appraiserRole.name",
      sortable: false,
    },
    {
      text: "Mission",
      value: "missionLifecycle.name",
      sortable: false,
    },
    {
      text: "Week Ending At",
      value: "createdAt",
      sortable: true,
      custom: true,
    },
    {
      text: "Status",
      value: "surveyAssignment.state",
      sortable: false,
      custom: true,
    },
  ]

  gotoAppraisalSchedulePage() {
    this.$router.push({
      name: this.$routes.AppraisalSchedule,
    })
  }

  gotoDetailPage(appraisal: MissionPerformanceAppraisal) {
    this.$router.push({
      name: this.$routes.SurveyDetail,
      params: { id: appraisal.surveyAssignment.id.toString() },
    })
  }

  onperformanceAppraisalsResult(result: FetchResult<PerformanceAppraisalsQueryQuery>) {
    if (result.data && result.data.missionPerformanceAppraisals) {
      this.pendingCount = result.data.missionPerformanceAppraisals.data.length
      this.totalCount = result.data.missionPerformanceAppraisals.pagination.totalSize
    }
  }

  refetchQueries() {
    return [
      {
        query: this.performanceAppraisalsQuery,
        variables: { ...this.performanceAppraisalsQueryVars },
      },
    ]
  }

  mounted() {
    // Set consultantId filter on filter bar
    this.$refs.table
  }
}
