var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":"Performance Data"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',{staticClass:"grey--text"},[_vm._v("View consultant performance appraisals and schedule")])]},proxy:true}])},[_c('div',{staticClass:"d-flex justify-between mb-4"},[_c('h3',{staticClass:"m-0 p-0"},[_vm._v("All Appraisals")]),_c('SecondaryButton',{on:{"click":_vm.gotoAppraisalSchedulePage}},[_vm._v("Appraisal Schedule")])],1),_c('Table',{ref:"table",staticClass:"px-0",attrs:{"query":_vm.performanceAppraisalsQuery,"queryKey":"missionPerformanceAppraisals","headers":_vm.headers,"exportOptions":{
      headers: [
        {
          text: 'Appraisee',
          value: 'appraisee.name',
        },
        { text: 'Appraisee Role', value: 'appraiseeRole.name' },
        { text: 'Appraiser ', value: 'appraiser.name' },
        { text: 'Appraiser Role', value: 'appraiserRole.name' },
        { text: 'Mission', value: 'missionLifecycle.name' },
        { text: 'Status', value: 'surveyAssignment.state' },
        { text: 'Week Ending At', value: 'createdAt' },
      ],
    },"listFilters":[
      {
        field: 'state',
        label: 'Status',
        filters: { PENDING: 'Pending', COMPLETED: 'Completed' },
        default: '',
      },
      {
        field: 'appraiserIdIn',
        label: 'Appraiser',
        queryFilter: {
          query: _vm.consultantsSearchQuery,
          itemValue: 'id',
          listKey: 'consultants',
          variables: { filter: { status: 'APPROVED' } },
          per: 200,
          returnObject: true,
          searchable: true,
        },
        icon: 'la-user-alt',
      },
      {
        field: 'appraiseeIdIn',
        label: 'Appraisee',
        queryFilter: {
          query: _vm.consultantsSearchQuery,
          itemValue: 'id',
          listKey: 'consultants',
          variables: { filter: { status: 'APPROVED' } },
          per: 200,
          returnObject: true,
          searchable: true,
        },
        icon: 'la-user-alt',
      },
      {
        field: 'missionIdIn',
        label: 'Mission',
        queryFilter: {
          query: _vm.missionLifecycleSelectquery,
          itemValue: 'id',
          variables: {
            filter: { stateIn: ['execution_stage', 'paused', 'completed'] },
          },
          listKey: 'missionLifecycles',
          per: 500,
          returnObject: true,
        },
        icon: 'la-rocket',
      },
    ],"dateFilters":[
      {
        field: 'createdAtDate',
        label: 'Created',
      },
    ]},on:{"click:row":_vm.gotoDetailPage},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.$moment(item.createdAt).endOf("week").subtract(1, "days"), "Do MMMM YYYY")))])]}},{key:`item.surveyAssignment.state`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.surveyAssignment.state === 'completed'
            ? 'green lighten-5 green--text'
            : 'amber lighten-5 amber--text text--darken-2',"small":""}},[_vm._v(_vm._s(item.surveyAssignment.state))])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }